import "./CSS/App.css";
import Home from "./Components/Home";
import React from "react";

function App() {
  return (
    <div>
      <Home />
    </div>
  );
}

export default App;
